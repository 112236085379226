import "../cards/serviceCard.css";

function ServiceCard(props) {
  return (
    <div
      className="
                    bg-light
                    shadow-lg
                    rounded-3
                    p-4
                    mb-grid-gutter
                    text-center text-sm-start
                  "
    >
      <img
        className="d-inline-block mb-4 mt-2"
        src={props.icon}
        width="80"
        alt="icon"
      />
      <h3 className="h5 mb-2">{props.title}</h3>
      <p className="fs-sm">{props.desc}</p>
    </div>
  );
}

export default ServiceCard;
