function InfluencerDetails() {
  return (
    <>
      <p>
        Learn the fundamentals of Influencer marketing. Influencer marketing is
        a type of social media marketing that uses endorsements and product
        mentions from influencers–individuals who have a dedicated social
        following and are viewed as experts within their niche.
      </p>
      <p>
        Influencer marketing works because of the high amount of trust that
        social influencers have built up with their following, and
        recommendations from them serve as a form of social proof to your
        brand’s potential customers.
      </p>
    </>
  );
}

export default InfluencerDetails;
