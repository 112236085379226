function InfluencerToLearn() {
  return (
    <>
      <ul>
        <li>Planning and Strategy</li>
        <li>Research-oriented.</li>
        <li>Community Building.</li>
        <li>Content Marketing.</li>
        <li>Analytics Skills.</li>
      </ul>
    </>
  );
}

export default InfluencerToLearn;
