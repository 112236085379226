import CourseDetails from "../components/layout/CourseDetails";
import bootstrap from "../assets/bootstrap.png";
import BootstrapPayment from "../components/payments/BootstrapPayment";
import BootstrapDetails from "../components/shortdetails/BootstrapDetails";
import BootstrapToLearn from "../components/shortdetails/BootstrapToLearn";
import BootstrapOutline from "../components/shortdetails/BootstrapOutline";

function Bootstrap() {
  return (
    <>
      <CourseDetails
        heading="Bootstrap"
        img={bootstrap}
        price={30000}
        destination="Online Class"
        time={36}
        date="11th July 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<BootstrapPayment />}
        courseDetails={<BootstrapDetails />}
        toLearn={<BootstrapToLearn />}
        courseOutline={<BootstrapOutline />}
      />
    </>
  );
}

export default Bootstrap;
