function TrustCard(props) {
  return (
    <div className="card border-0 shadow card-hover py-3 py-sm-4">
      <div className="card-body px-1 py-0 text-center">
        <img src={props.image} alt="Brand" style={{ width: 130 }} />
      </div>
    </div>
  );
}

export default TrustCard;
