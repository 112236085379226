import styles from "../../css/coursedetails.module.css";

function WebDevOutline() {
  return (
    <>
      <p>
        <strong>Courses available in this program:</strong>
      </p>
      <ul className={styles.learnStruct}>
        <li>HTML5</li>
        <li>CSS3</li>
        <li>Javascript</li>
        <li>Bootstrap 5</li>
      </ul>
    </>
  );
}

export default WebDevOutline;
