function OfficePayment() {
  return (
    <>
      <a
        href="https://paystack.com/pay/pu29j-yz8q"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button
          style={{
            padding: "14px 40px",
            fontSize: 18,
            fontWeight: 600,
            borderRadius: 25,
            backgroundColor: "#0d6efd",
            borderColor: "#0d6efd",
            color: "#fff",
            textAlign: "center",
          }}
        >
          ₦ 70,000 ENROLL
        </button>
      </a>
    </>
  );
}

export default OfficePayment;
