import CourseDetails from "../components/layout/CourseDetails";
import javascript from "../assets/javascript.png";
import JavascriptPayment from "../components/payments/JavascriptPayment";
import JavascriptDetails from "../components/shortdetails/JavascriptDetails";
import JavascriptToLearn from "../components/shortdetails/JavascriptToLearn";
import JavascriptOutline from "../components/shortdetails/JavascriptOutline";

function Javascript() {
  return (
    <>
      <CourseDetails
        heading="Javascript"
        img={javascript}
        destination="Online Class"
        time={40}
        date="8th August 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<JavascriptPayment />}
        courseDetails={<JavascriptDetails />}
        toLearn={<JavascriptToLearn />}
        courseOutline={<JavascriptOutline />}
      />
    </>
  );
}

export default Javascript;
