import { NavLink } from "react-router-dom";

import homepage from "../../assets/homepage.png";

function CarouselComp() {
  return (
    <div className="container px-4 py-5">
      <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
        <div className="col-md-6 col-sm-8 me-auto ms-auto mb-5">
          <img
            src={homepage}
            className="d-block mx-lg-auto img-fluid"
            alt="CodeCamp Teamwork"
            width="700"
            height="500"
            loading="lazy"
          />
        </div>
        <div className="col-md-6 mt-auto mb-auto">
          <h1 className="display-5 fw-bold lh-1 mb-3">
            Take your Business to the next level
          </h1>
          <p className="lead mb-3">
            Empowering Businesses with Cutting-Edge Digital Solutions. We
            Transform Your Offline Presence into a Thriving Digital Enterprise.
          </p>
          <div className="d-grid gap-2 d-md-flex justify-content-md-start">
            <NavLink className="nav-link" to="pricing">
              <button
                type="button"
                className="btn btn-primary btn-lg px-4 me-md-2"
              >
                Get Started
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarouselComp;
