function CorelDrawDetails() {
  return (
    <>
      <p>
        Learning the fundamentals of Corel Draw. CorelDraw is used primarily for
        marketing and advertising businesses, particularly those that specialize
        in print advertising development.
      </p>
      <p>
        Outside of logos, CorelDraw is a program used in the professional space
        to create brochures, newsletters, business card etc.
      </p>
    </>
  );
}

export default CorelDrawDetails;
