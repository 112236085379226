function CssToLearn() {
  return (
    <>
      <ul>
        <li>
          How to control the presentation of multiple pages of a website with a
          single style sheet
        </li>

        <li>How to easily apply same style rules on multiple elements</li>
        <li>
          How to change the position of an element on a web page without
          changing the markup
        </li>
        <li>How to alter the display of existing HTML elements.</li>
        <li>
          How to create animations and transitions effects without using any
          JavaScript.
        </li>
        <li>How to present the same page differently on different devices.</li>
      </ul>
    </>
  );
}

export default CssToLearn;
