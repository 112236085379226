function DigitalToLearn() {
  return (
    <>
      <ul>
        <li>How to Take a business online.</li>

        <li>How to Make it easy for people to find a business on the web.</li>
        <li>How to Reach more people locally, on social media or on mobile.</li>
        <li>How to Reach more customers with advertising.</li>
        <li>How to Track and measure web traffic.</li>
        <li>How to Sell products or services online.</li>
        <li>How to Take a business global.</li>
      </ul>
    </>
  );
}

export default DigitalToLearn;
