import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// const app = initializeApp({
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// });

const app = initializeApp({
  apiKey: "AIzaSyDxP_iMvjKhxs6Srh4ckiBIPcNT3Q-BfIQ",
  authDomain: "codecamp-nigeria.firebaseapp.com",
  projectId: "codecamp-nigeria",
  storageBucket: "codecamp-nigeria.appspot.com",
  messagingSenderId: "1084576532297",
  appId: "1:1084576532297:web:ddcd6d7779b5e4c1323c98",
  measurementId: "G-JSFBVMJB6J",
});

export const db = getFirestore(app);
export default app;
