import CourseDetails from "../components/layout/CourseDetails";
import figma from "../assets/figma.png";
import UiUxDetails from "../components/shortdetails/UiUxDetails";
import UiUxToLearn from "../components/shortdetails/UiUxToLearn";
import UiUxOutline from "../components/shortdetails/UiUxOutline";
import UiUxPayment from "../components/payments/UiUxPayment";

function UiUx() {
  return (
    <>
      <CourseDetails
        heading="Design with Figma"
        img={figma}
        price={60000}
        destination="Online Class"
        time={36}
        date="12th September 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<UiUxPayment />}
        courseDetails={<UiUxDetails />}
        toLearn={<UiUxToLearn />}
        courseOutline={<UiUxOutline />}
      />
    </>
  );
}

export default UiUx;
