function BootstrapToLearn() {
  return (
    <>
      <ul>
        <li>How to easily create responsive websites.</li>

        <li>
          How to quickly create multi-column layout with pre-defined classes.
        </li>
        <li>How to quickly create different types of form layouts.</li>
        <li>How to quickly create different variation of navigation bar.</li>
        <li>
          How to easily create components like accordions, modals, etc. without
          writing any JS code.
        </li>
        <li>
          How to easily create carousel or image slider to showcase your
          content..
        </li>
      </ul>
    </>
  );
}

export default BootstrapToLearn;
