import { Link } from "react-router-dom";

function ErrorPage() {
  return (
    <div>
      <section className="section-border border-primary">
        <div className="container d-flex flex-column">
          <div className="row align-items-center justify-content-center gx-0 min-vh-100">
            <div className="col-12 col-md-5 col-lg-4 py-8 py-md-11">
              <h1 className="display-3 fw-bold text-center">Sorry</h1>
              <p className="mb-5 text-center text-muted">
                We are currently working to bring this page to you
              </p>
              <div className="text-center">
                <Link className="btn btn-primary" to="/">
                  Back to Home Page
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ErrorPage;
