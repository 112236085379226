import frontend from "../assets/frontend.png";
import CourseDetails from "../components/layout/CourseDetails";
import FrontendPayment from "../components/payments/FrontendPayment";
import FrontendDetails from "../components/shortdetails/FrontendDetails";
import FrontendOutline from "../components/shortdetails/FrontendOutline";
import FrontendToLearn from "../components/shortdetails/FrontendToLearn";

function FrontEnd() {
  return (
    <>
      <CourseDetails
        heading="Frontend Developement"
        img={frontend}
        price={300000}
        destination="Online Class"
        time={70}
        date="22nd August 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<FrontendPayment />}
        courseDetails={<FrontendDetails />}
        toLearn={<FrontendToLearn />}
        courseOutline={<FrontendOutline />}
      />
    </>
  );
}

export default FrontEnd;
