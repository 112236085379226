import TrustCard from "../cards/TrustCard";
import wgbt from "../../assets/wgbt.png";
import topaz from "../../assets/topaz.jpg";
import klover from "../../assets/klover.png";
import aptech from "../../assets/aptech.png";
import investify from "../../assets/investify.png";
import houseofroses from "../../assets/hornew.png";

function Trustees() {
  return (
    <div>
      <section className="container pt-2 pt-md-0 pb-5 pb-md-6 pb-lg-7">
        <h2 className="text-center mb-5">Trusted by great companies</h2>
        <div className="row">
          <div className="col-md-3 col-sm-4 col-6 mb-grid-gutter">
            <TrustCard image={wgbt} />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-grid-gutter">
            <TrustCard image={topaz} />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-grid-gutter">
            <TrustCard image={aptech} />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-grid-gutter">
            <TrustCard image={klover} />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-grid-gutter">
            <TrustCard image={investify} />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-grid-gutter">
            <TrustCard image={houseofroses} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Trustees;
