import CourseDetails from "../components/layout/CourseDetails";
import css from "../assets/css3.png";
import CssPayment from "../components/payments/CssPayment";
import CssDetails from "../components/shortdetails/CssDetails";
import CssToLearn from "../components/shortdetails/CssToLearn";
import CssOutline from "../components/shortdetails/CssOutline";

function Css() {
  return (
    <>
      <CourseDetails
        heading="CSS (Cascading Style Sheets)"
        img={css}
        destination="Online Class"
        time={40}
        date="11th July 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<CssPayment />}
        courseDetails={<CssDetails />}
        toLearn={<CssToLearn />}
        courseOutline={<CssOutline />}
      />
    </>
  );
}

export default Css;
