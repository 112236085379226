function ReactToLearn() {
  return (
    <>
      <ul>
        <li>Installation</li>
        <li>Build Reusable components.</li>
        <li>Conditional rendering.</li>
        <li>React Hooks.</li>
        <li>Make API calls.</li>
        <li>Async functions.</li>
        <li>Props vs State.</li>
      </ul>
    </>
  );
}

export default ReactToLearn;
