import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import graphicIcon from "../assets/swatches.png";
import socialIcon from "../assets/social-media.png";
import webIcon from "../assets/web-design.png";
import videoIcon from "../assets/clapperboard.png";
import techIcon from "../assets/technology.png";

function Services() {
  return (
    <div>
      <section className="py-md-2 py-3 bg-dark">
        <div className="container mt-5 py-lg-4">
          <div className="col-lg-8 col-md-10 mx-auto">
            <h1 className="display-4 text-center text-light">Our Services</h1>
            <p className="mb-sm-5 pb-3 lead text-center text-light opacity-60">
              We provide professional I.T Services that helps organisations
              improve performance and gain competitive edge in their various
              spheres of operations. CodeCamp Nigeria has delivered on many
              projects in the past and we believe we possess the requisite
              skills to work with your esteemed organization in achieving the
              desired value for your clients.
            </p>
          </div>
        </div>
      </section>

      <Container className="mt-5 mb-5">
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <div className="rounded-3 shadow-sm mb-3 alert alert-info">
              <Accordion.Header>Graphic Design</Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-md-row flex-column align-items-md-center">
                  <div className="me-md-4 mb-md-0 mb-3">
                    <img
                      src={graphicIcon}
                      alt="Web Development"
                      style={{ width: 100 }}
                    />
                  </div>
                  <div className="ps-md-3">
                    <p className="mb-0">
                      There's no limit to what you can get designed at CodeCamp.
                      Whether you're looking for a spectacular new logo or some
                      stunning flyers, the talented team of designers at
                      CodeCamp can make it happen. CodeCamp prides itself on
                      it's high quality designers who deliver only the best
                      graphic design services.
                    </p>
                  </div>
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <div className="rounded-3 shadow-sm mb-3 alert alert-info">
              <Accordion.Header>Web Development</Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-md-row flex-column align-items-md-center">
                  <div className="me-md-4 mb-md-0 mb-3">
                    <img
                      src={webIcon}
                      alt="Web Development"
                      style={{ width: 100 }}
                    />
                  </div>
                  <div className="ps-md-3">
                    <p className="mb-0">
                      Web development services are used to design, build,
                      support, and evolve all types of web-based software. With
                      years of experience in web development, CodeCamp
                      delivers intuitive and fast websites, web portals, and
                      other web solutions that bring about digital
                      transformation and enhance business workflows.
                    </p>
                  </div>
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <div className="rounded-3 shadow-sm mb-3 alert alert-info">
              <Accordion.Header>Social Media Management</Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-md-row flex-column align-items-md-center">
                  <div className="me-md-4 mb-md-0 mb-3">
                    <img
                      src={socialIcon}
                      alt="Web Development"
                      style={{ width: 100 }}
                    />
                  </div>
                  <div className="ps-md-3">
                    <p className="mb-0">
                      Our social media management services is a part of the
                      overall strategy and hands-on methodologies that we offer
                      our clients to expose their services and products. You
                      know that you need to have a strong presence on social
                      media, it’s where all your customers hang out. You
                      understand its importance and the potential it packs in
                      terms of bringing new business and increasing brand
                      awareness. But who has time for that, right? Managing
                      social media effectively can take time out of your
                      day time you know you could invest elsewhere. We
                      definitely get you. CodeCamp team knows how handle social
                      media marketing services for both small businesses and
                      large enterprises. Let us handle your  social media
                      management so you’ll get back to do what you do best…
                    </p>
                  </div>
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <div className="rounded-3 shadow-sm mb-3 alert alert-info">
              <Accordion.Header>Video Animation</Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-md-row flex-column align-items-md-center">
                  <div className="me-md-4 mb-md-0 mb-3">
                    <img
                      src={videoIcon}
                      alt="Web Development"
                      style={{ width: 100 }}
                    />
                  </div>
                  <div className="ps-md-3">
                    <p className="mb-0">
                      Your buyers want to know you. They want to see your
                      excellence. Our animators connect you with your prospects
                      and turn them into sales-ready leads.
                    </p>
                  </div>
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <div className="rounded-3 shadow-sm mb-3 alert alert-info">
              <Accordion.Header>I.T Related Services</Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-md-row flex-column align-items-md-center">
                  <div className="me-md-4 mb-md-0 mb-3">
                    <img
                      src={techIcon}
                      alt="Web Development"
                      style={{ width: 100 }}
                    />
                  </div>
                  <div className="ps-md-3">
                    <p className="mb-0">
                      Information technology is the heart of most businesses
                      operations and it is often a key driver and enabler of
                      change. At CodeCamp, we work with clients on how to use
                      Information Technology in achieving their set goals and
                      objectives. We deliver the technological solutions
                      organizations need to compete and grow by partnering with
                      best-in-class technologies and solution sets.
                    </p>
                  </div>
                </div>
              </Accordion.Body>
            </div>
          </Accordion.Item>
        </Accordion>
      </Container>
    </div>
  );
}

export default Services;
