function FrontendDetails() {
  return (
    <>
      <p>
        FrontEnd Developer is responsible for the design and implementation of
        the web interface.
      </p>
      <p>
        The users require this interface so that they can access the application
        in question. The front end developer makes sure that the design works
        online by using coding languages such as CSS, HTML, JavaScript and
        React.
      </p>
      <p>
        Learning Frontend Development you would be able to design high end
        userinterfaces and you would be able to consume APIs.
      </p>
    </>
  );
}

export default FrontendDetails;
