import CourseDetails from "../components/layout/CourseDetails";
import copywriting from "../assets/copywriting.png";
import CopywritingToLearn from "../components/payments/CopywritingToLearn";
import CopywritingDetails from "../components/payments/CopywritingDetails";
import CopywritingPayment from "../components/payments/CopywritingPayment";

function CopyWriting() {
  return (
    <>
      <CourseDetails
        heading="Copywriting"
        img={copywriting}
        price={50000}
        destination="Online Class"
        time={36}
        date="21st September 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<CopywritingPayment />}
        courseDetails={<CopywritingDetails />}
        toLearn={<CopywritingToLearn />}
        // courseOutline={<ReactOutline />}
      />
    </>
  );
}

export default CopyWriting;
