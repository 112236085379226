function GraphicsToLearn() {
  return (
    <>
      <ul>
        <li>
          Have a deep understanding of typography, color theory, photos, layout,
          blocking and other design theory and skills
        </li>

        <li>Create logos and branding packages for potential clients</li>
        <li>
          Understand solid logo design and ad design principles to create
          compelling campaigns and logos
        </li>
        <li>
          Learn a few of the upcoming design trends for 2022 and how to create
          them!
        </li>
        <li>Learn How to Encourage and Spark Creativity.</li>
        <li>How to properly give and receive critiques and feedback</li>
        <li>
          Work through an event flyer design to understand strong type
          hierarchy, balance and overall design layout.
        </li>
      </ul>
    </>
  );
}

export default GraphicsToLearn;
