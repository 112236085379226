import CourseDetails from "../components/layout/CourseDetails";
import influencer from "../assets/influencer.png";
import InfluencerDetails from "../components/shortdetails/InfluencerDetails";
import InfluencerToLearn from "../components/shortdetails/InfluencerToLearn";
import InfluencerPayment from "../components/payments/InfluencerPayment";

function Influencer() {
  return (
    <>
      <CourseDetails
        heading="Influencer Marketing"
        img={influencer}
        price={50000}
        destination="Online Class"
        time={36}
        date="21st September 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<InfluencerPayment />}
        courseDetails={<InfluencerDetails />}
        toLearn={<InfluencerToLearn />}
        // courseOutline={<ReactOutline />}
      />
    </>
  );
}

export default Influencer;
