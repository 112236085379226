function WebDevToLearn() {
  return (
    <>
      <ul>
        <li>
          How to use the latest HTML 5 standard to HTML is used to format text,
          create hyperlinks, tables, lists, forms, display images and more.
        </li>

        <li>
          How to use CSS to change the appearance and layout of all the pages in
          a Web site.
        </li>

        <li>
          How to use JavaScript to improve the design, validate forms, detect
          browsers, create cookies, and much more.
        </li>
      </ul>
    </>
  );
}

export default WebDevToLearn;
