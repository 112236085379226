function JavascriptDetails() {
  return (
    <>
      <p>
        JavaScript is the most popular and widely used client-side scripting
        language.
      </p>
      <p>
        Client-side scripting refers to scripts that run within your web
        browser.
      </p>
      <p>
        JavaScript is designed to add interactivity and dynamic effects to the
        web pages by manipulating the content returned from a web server.
      </p>
    </>
  );
}

export default JavascriptDetails;
