function UiUxDetails() {
  return (
    <>
      <p>
        Figma is a powerful design tool that helps you to create anything:
        websites, applications, logos, and much more. These skills are essential
        for building a great portfolio for yourself and potentially for your own
        company.
      </p>
      <p>
        By learning to use Figma, you'll take your first steps into User
        Interface Design and and User Experience Design.
      </p>
      <p>
        These skills are essential for building a great portfolio for yourself
        and potentially for your own company.
      </p>
    </>
  );
}

export default UiUxDetails;
