import { collection, getDocs } from "firebase/firestore";
import { useEffect } from "react";
import CarouselComp from "../components/layout/Carousel";
// import Consult from "../components/layout/Consult";
import OurService from "../components/layout/OurService";
// import Team from "../components/layout/Team";
import Trustees from "../components/layout/Trustees";
import { db } from "../firebase";

function Home() {
  useEffect(() => {
    const fetchData = async () => {
      await getDocs(collection(db, "feedback")).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          console.log(doc.data());
        });
      });
    };

    fetchData();
  }, []);
  return (
    <div>
      <CarouselComp />
      <OurService />
      <Trustees />
    </div>
  );
}

export default Home;
