import CourseDetails from "../components/layout/CourseDetails";
import powerpoint from "../assets/powerpoint.png";

function PowerPoint() {
  return (
    <>
      <CourseDetails
        heading="Microsoft Power Point"
        img={powerpoint}
        price={30000}
        destination="Online Class"
        time={36}
        date="21st September 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        // enroll={<ReactPayment />}
        // courseDetails={<ReactDetails />}
        // toLearn={<ReactToLearn />}
        // courseOutline={<ReactOutline />}
      />
    </>
  );
}

export default PowerPoint;
