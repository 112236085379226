import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Pricing from "./pages/Pricing";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
// import PricingLayout from "./components/layout/PricingLayout";
import ErrorPage from "./components/layout/ErrorPage";
import Contact from "./pages/Contact";
import Learn from "./pages/Learn";
import WebDevelopment from "./pages/WebDevelopment";
import Html from "./pages/Html";
import Css from "./pages/Css";
import Javascript from "./pages/Javascript";
import Bootstrap from "./pages/Bootstrap";
import FrontEnd from "./pages/Frontend";
import React from "./pages/React";
import Digital from "./pages/Digital";
import Office from "./pages/Office";
import UiUx from "./pages/UiUx";
import CorelDraw from "./pages/CorelDraw";
import Affiliate from "./pages/Affiliate";
import Influencer from "./pages/Influencer";
import CopyWriting from "./pages/CopyWriting";
import Word from "./pages/Word";
import PowerPoint from "./pages/PowerPoint";
import Excel from "./pages/Excel";
import Graphics from "./pages/Graphics";
import Restructure from "./restructure/restructure";

function App() {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="shop" element={<ErrorPage />} />
        <Route path="services" element={<Services />} />
        <Route path="pricing" element={<Pricing />}>
          <Route index path="graphics" element={<ErrorPage />} />
          <Route path="web-dev" element={<ErrorPage />} />
          <Route path="social-media" element={<ErrorPage />} />
          <Route path="video-animation" element={<ErrorPage />} />
          <Route path="it-services" element={<ErrorPage />} />
        </Route>
        <Route path="contact" element={<Contact />} />
        <Route path="learn" element={<Learn />} />
        <Route path="learn/frontend" element={<FrontEnd />} />
        <Route path="learn/webdevelopment" element={<WebDevelopment />} />
        <Route path="learn/html" element={<Html />} />
        <Route path="learn/css" element={<Css />} />
        <Route path="learn/javascript" element={<Javascript />} />
        <Route path="learn/bootstrap" element={<Bootstrap />} />
        <Route path="learn/react" element={<React />} />
        <Route path="learn/digital" element={<Digital />} />
        <Route path="learn/office" element={<Office />} />
        <Route path="learn/graphics" element={<Graphics />} />
        <Route path="learn/uiux" element={<UiUx />} />
        <Route path="learn/coreldraw" element={<CorelDraw />} />
        <Route path="learn/affiliate" element={<Affiliate />} />
        <Route path="learn/influencer" element={<Influencer />} />
        <Route path="learn/copywriting" element={<CopyWriting />} />
        <Route path="learn/word" element={<Word />} />
        <Route path="learn/powerpoint" element={<PowerPoint />} />
        <Route path="learn/excel" element={<Excel />} />
        <Route path="learn/closed" element={<ErrorPage />} />
        <Route path="restructure" element={<Restructure />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
