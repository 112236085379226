import learnimage from "../../assets/learnhead.png";

function LearnHeader() {
  return (
    <>
      <div className="bg py-5">
        <div className="container" style={{ backgroundColor: "#754ffe" }}>
          <div className="row align-items-center g-0">
            <div className="col-md-6">
              <div className="py-5 px-5">
                <h1 className="text-white display-4 fw-bold">
                  Welcome to CodeCamp Learn
                </h1>
                <p className="lead text-white-50">
                  Learn in-demand Tech Skills. Getting a tech skill is one of
                  the smartest ways to get a high paying job.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img src={learnimage} alt="codecamp learn" height="300px" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LearnHeader;
