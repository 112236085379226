import { FiMail, FiPhone } from "react-icons/fi";
import Sequence from "../components/cards/Sequence";
import Form from "../components/Form";

function Contact() {
  return (
    <>
      <div className="container pt-5 pb-5">
        <div className="text-center">
          <Sequence icon={<FiPhone />} title="Phone" para="+234 807 785 7683" />
          <Sequence
            icon={<FiMail />}
            title="Email"
            para="contact@codecamp.ng"
          />
        </div>
        <Form />
      </div>
    </>
  );
}

export default Contact;
