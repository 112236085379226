function FrontendToLearn() {
  return (
    <>
      <ul>
        <li>Web Development Basics</li>
        <li>How to make interactive web apps.</li>
        <li>CSS Concepts.</li>
        <li>Javascript Concepts.</li>
        <li>Working with APIs.</li>
        <li>Beginner to Advanced React JS</li>
        <li>Getting Hired</li>
      </ul>
    </>
  );
}

export default FrontendToLearn;
