import ServiceCard from "../cards/serviceCard";
import { Link } from "react-router-dom";
import graphicIcon from "../../assets/swatches.png";
import socialIcon from "../../assets/social-media.png";
import webIcon from "../../assets/web-design.png";
import videoIcon from "../../assets/clapperboard.png";
import techIcon from "../../assets/technology.png";
import "./OurService.css";

function OurService() {
  return (
    <div>
      <section className="container py-5 py-md-6 py-lg-7 my-3">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-5 text-center text-md-start mb-5 mb-md-0">
            <h2 className="mb-3">Our Services</h2>
            <p className="text-muted mb-4 pb-2">
              We provide professional I.T Services that helps organisations
              improve performance and gain competitive edge in their various
              spheres of operations.
            </p>
            <div className="btn btn-primary">
              <Link
                to="services"
                style={{ color: "white", textDecoration: "none" }}
              >
                See All Services
              </Link>
            </div>
          </div>
          <div className="col-lg-8 col-md-7 bg-position-center bg-repeat-0 backg">
            <div className="mx-auto" style={{ maxWidth: 610 }}>
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <ServiceCard
                    title="Graphic Design"
                    desc="CodeCamp prides itself on it's high quality designers who deliver only the best graphic design services."
                    icon={graphicIcon}
                  />
                  <ServiceCard
                    title="Social Media Management"
                    desc="Our S.M.M services is a part of the overall strategy and hands-on methodologies that we offer our clients to expose their services and products."
                    icon={socialIcon}
                  />
                  <ServiceCard
                    title="Web Development"
                    desc="With years of experience in web development,  CodeCamp delivers intuitive and fast websites, web portals, and other web solutions to you."
                    icon={webIcon}
                  />
                </div>
                <div className="col-sm-6">
                  <ServiceCard
                    title="Video Animation"
                    desc="Your buyers want to know you. They want to see your excellence. Our animators connect you with your prospects and turn them into sales-ready leads."
                    icon={videoIcon}
                  />
                  <ServiceCard
                    title="I.T Related Services"
                    desc="Information technology is the heart of most businesses operations and it is often a key driver and enabler of change."
                    icon={techIcon}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OurService;
