import { NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from "react-bootstrap";
import logo from "../../assets/logo.png";

function Header() {
  return (
    <>
      <div className="mb-5">
        <Navbar bg="light" expand="lg" fixed="top" className="p-2">
          <Container>
            <Navbar.Brand>
              <NavLink
                to="/"
                style={{ textDecoration: "none", color: "black" }}
              >
                <img
                  src={logo}
                  alt="CodeCamp Logo"
                  style={{ width: 50 }}
                  className="logo"
                />
                CodeCamp Technologies
              </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto ms-auto">
                <NavLink
                  className="nav-link"
                  to="/"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "#0d6efd",
                          textDecoration: "none",
                        }
                      : { color: "#202020", textDecoration: "none" }
                  }
                >
                  Home
                </NavLink>
                {/* <NavLink
                  className="nav-link"
                  to="shop"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "#0d6efd",
                          textDecoration: "none",
                        }
                      : { color: "#202020", textDecoration: "none" }
                  }
                >
                  Shop
                </NavLink> */}
                <NavLink
                  className="nav-link"
                  to="services"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "#0d6efd",
                          textDecoration: "none",
                        }
                      : { color: "#202020", textDecoration: "none" }
                  }
                >
                  Our Services
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="pricing"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "#0d6efd",
                          textDecoration: "none",
                        }
                      : { color: "#202020", textDecoration: "none" }
                  }
                >
                  Pricing
                </NavLink>
                <NavLink
                  className="nav-link"
                  to="contact"
                  style={({ isActive }) =>
                    isActive
                      ? {
                          color: "#0d6efd",
                          textDecoration: "none",
                        }
                      : { color: "#202020", textDecoration: "none" }
                  }
                >
                  Contact Us
                </NavLink>
              </Nav>
              <Nav className="justify-content-end">
                <NavLink className="nav-link" to="learn">
                  <button className="btn btn-primary">
                    Learn with CodeCamp
                  </button>
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default Header;
