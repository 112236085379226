import styles from "../../css/coursedetails.module.css";

function OfficeOutline() {
  return (
    <>
      <p>
        <strong>
          The following Courses are also available in this program:
        </strong>
      </p>
      <ul className={styles.learnStruct}>
        <li>Microsoft Word</li>
        <li>Microsoft Excel</li>
        <li>Microsoft PowerPoint</li>
      </ul>
    </>
  );
}

export default OfficeOutline;
