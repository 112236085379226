import CourseDetails from "../components/layout/CourseDetails";
import digital from "../assets/digital.png";
import DigitalToLearn from "../components/shortdetails/DigitalToLearn";
import DigitalDetails from "../components/shortdetails/DigitalDetails";
import DigitalPayment from "../components/payments/DigitalPayment";

function Digital() {
  return (
    <>
      <CourseDetails
        heading="Digital Marketing"
        img={digital}
        price={30000}
        destination="Online Class"
        time={40}
        date="12th September 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<DigitalPayment />}
        courseDetails={<DigitalDetails />}
        toLearn={<DigitalToLearn />}
        //   courseOutline={<BootstrapOutline />}
      />
    </>
  );
}

export default Digital;
