function HtmlDetails() {
  return (
    <>
      <p>
        Start learning HTML with the codecamp course and lay the foundations of
        your Web Development skills.
      </p>
      <p>
        HTML stands for HyperText Markup Language. HTML is the basic building
        block of World Wide Web
      </p>
      <p>
        Apart from text, hypertext may contain tables, lists, forms, images, and
        other presentational elements. It is an easy-to-use and flexible format
        to share information over the Internet.
      </p>
    </>
  );
}

export default HtmlDetails;
