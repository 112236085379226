import CourseDetails from "../components/layout/CourseDetails";
import html from "../assets/html5.png";
import HtmlPayment from "../components/payments/HtmlPayment";
import HtmlDetails from "../components/shortdetails/HtmlDetails";
import HtmlToLearn from "../components/shortdetails/HtmlToLearn";
import HtmlOutline from "../components/shortdetails/HtmlOutline";

function Html() {
  return (
    <>
      <CourseDetails
        heading="HTML (HyperText Markup Language) "
        img={html}
        destination="Online Class"
        time={40}
        date="13th June 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<HtmlPayment />}
        courseDetails={<HtmlDetails />}
        toLearn={<HtmlToLearn />}
        courseOutline={<HtmlOutline />}
      />
    </>
  );
}

export default Html;
