import styles from "../../css/coursedetails.module.css";

function CourseDetails(props) {
  return (
    <>
      <div className="container py-5">
        <div className={styles.courseBG}>
          <div className="row">
            <div className="col-md-6">
              <div className={styles.imageContainer}>
                <img src={props.img} alt="web development" width="100%" />
              </div>
            </div>
            <div className="col-md-6">
              <h2 className={styles.courseHeading}>{props.heading}</h2>
              <p className={styles.courseDestination}>
                <strong>{props.destination}</strong>
              </p>
              <div className={styles.enrollButton}>{props.enroll}</div>
              <div>{props.courseOutline}</div>
            </div>
          </div>
        </div>
        <div>
          <div>{props.courseDetails}</div>
          <hr />
          <p>
            <strong>Time to complete:</strong> {props.time} Hours{" "}
            <strong>Language:</strong> English <strong>Start Date: </strong>
            {props.date}
          </p>
          <hr />
          <p>{props.courseModule}</p>
          <p>
            <strong>What you will learn:</strong>
          </p>
          <div>{props.toLearn}</div>
        </div>
        <div className={styles.footer}>
          <h3>Sign up for this program today!</h3>
          <div>{props.enroll}</div>
        </div>
      </div>
    </>
  );
}

export default CourseDetails;
