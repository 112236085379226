import CourseDetails from "../components/layout/CourseDetails";
import affiliate from "../assets/affiliate.png";
import AffiliateDetails from "../components/shortdetails/AffiliateDetails";
import AffiliateToLearn from "../components/shortdetails/AffiliateToLearn";
import AffiliatePayment from "../components/payments/AffiliatePayment";

function Affiliate() {
  return (
    <>
      <CourseDetails
        heading="Affiliate Marketing"
        img={affiliate}
        price={20000}
        destination="Online Class"
        time={36}
        date="21st September 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<AffiliatePayment />}
        courseDetails={<AffiliateDetails />}
        toLearn={<AffiliateToLearn />}
        //   courseOutline={<ReactOutline />}
      />
    </>
  );
}

export default Affiliate;
