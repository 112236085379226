import styles from "../../css/coursedetails.module.css";

function UiUxOutline() {
  return (
    <>
      <p>
        <strong>This Course is also available in this program:</strong>
      </p>
      <ul className={styles.learnStruct}>
        <li>Graphic Design</li>
      </ul>
    </>
  );
}

export default UiUxOutline;
