function WebDevDetails() {
  return (
    <>
      <p>
        Start learning web development with the codecamp courses and lay the
        foundations of your web application development skills.
      </p>
      <p>
        Most applications used today are web based, this tech skill will allow
        you to develop high quality web sites that, work seamlessly on mobile,
        tablet, and large screen browsers accessible.
      </p>
      <p>
        Web developers are the programmers with the coding skills needed to add
        functionality to a website.
      </p>
    </>
  );
}

export default WebDevDetails;
