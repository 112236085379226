function DigitalDetails() {
  return (
    <>
      <p>
        Digital marketing is a means of advertising and selling products through
        the internet, mobile devices, social media, search engines, display
        advertising, e.t.c
      </p>
      <p>
        Learn the fundamentals of digital marketing, and help grow your business
        or career..
      </p>
    </>
  );
}

export default DigitalDetails;
