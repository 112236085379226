function AffiliateDetails() {
  return (
    <>
      <p>
        Learn the fundamentals of Affiliate marketing. Affiliate marketing is an
        advertising model in which a company compensates third-party publishers
        to generate traffic or leads to the company's products and services.
      </p>
      <p>
        The third-party publishers are affiliates, and the commission fee
        incentivise them to find ways to promote the company.
      </p>
    </>
  );
}

export default AffiliateDetails;
