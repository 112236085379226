function UiUxToLearn() {
  return (
    <>
      <ul>
        <li>Account setup</li>
        <li>Design file</li>
        <li>Design assets</li>
        <li>How to get started – the basics</li>
        <li>Frames</li>
        <li>Grid columns and rows</li>
        <li>Shapes</li>
        <li>Import images</li>
        <li>Labelling and grouping</li>
        <li>Navigation bar</li>
        <li>Button design & Lots more</li>
      </ul>
    </>
  );
}

export default UiUxToLearn;
