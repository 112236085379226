function JavascriptToLearn() {
  return (
    <>
      <ul>
        <li>
          How to modify the content of a web page by adding or removing
          elements.
        </li>

        <li>
          How to monitor events like mouse click, hover, etc. and react to it.
        </li>
        <li>
          How to create alert pop-ups to display info or warning messages to the
          user.
        </li>
        <li>
          How to perform operations based on user inputs and display the
          results.
        </li>
        <li>How to validate user inputs before submitting it to the server.</li>
      </ul>
    </>
  );
}

export default JavascriptToLearn;
