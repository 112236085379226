import CourseDetails from "../components/layout/CourseDetails";
import react from "../assets/react.png";
import ReactDetails from "../components/shortdetails/ReactDetails";
import ReactOutline from "../components/shortdetails/ReactOutline";
import ReactToLearn from "../components/shortdetails/ReactToLearn";
import ReactPayment from "../components/payments/ReactPayment";

function React() {
  return (
    <>
      <CourseDetails
        heading="React JS"
        img={react}
        price={150000}
        destination="Online Class"
        time={36}
        date="22nd August 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<ReactPayment />}
        courseDetails={<ReactDetails />}
        toLearn={<ReactToLearn />}
        courseOutline={<ReactOutline />}
      />
    </>
  );
}

export default React;
