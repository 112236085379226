import CourseDetails from "../components/layout/CourseDetails";
import graphics from "../assets/graphics.png";
import GraphicsDetails from "../components/shortdetails/GraphicsDetails";
import GraphicsToLearn from "../components/shortdetails/GraphicsToLearn";
import GraphicsPayment from "../components/payments/GraphicsPayment";

function Graphics() {
  return (
    <>
      <CourseDetails
        heading="Introduction to Graphics Design"
        img={graphics}
        price={50000}
        destination="Online Class"
        time={30}
        date="21st September 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<GraphicsPayment />}
        courseDetails={<GraphicsDetails />}
        toLearn={<GraphicsToLearn />}
        // courseOutline={<ReactOutline />}
      />
    </>
  );
}

export default Graphics;
