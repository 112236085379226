function Form() {
  return (
    <>
      <div className="container">
        <form
          style={{ backgroundColor: "#fff", padding: 30 }}
          className="mt-5 mb-5"
        >
          <div className="text-center mb-4">
            <h3 style={{ fontWeight: 700 }}>Send us a Message</h3>
            <p>Send suggestions and also ask questions</p>
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Name
            </label>
            <input className="form-control rounded" type="text" />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Email Address
            </label>
            <input className="form-control rounded" type="email" />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Phone Number
            </label>
            <input className="form-control rounded" type="number" />
          </div>
          <div className="mb-3">
            <label htmlFor="" className="form-label fw-bold">
              Message
            </label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              className="form-control"
            >
              Type Message
            </textarea>
          </div>
          <button className="btn btn-primary disabled">Send Message</button>
        </form>
      </div>
    </>
  );
}

export default Form;
