function AffiliateToLearn() {
  return (
    <>
      <ul>
        <li>Cost of start-up</li>
        <li>Ongoing costs.</li>
        <li>Risk.</li>
        <li>Targeted Traffic.</li>
        <li>Flexibility.</li>
        <li>High ROI</li>
        <li>How to market a product, not sell a product.</li>
        <li>How to build an offer, not a product.</li>
      </ul>
    </>
  );
}

export default AffiliateToLearn;
