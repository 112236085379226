import CourseDetails from "../components/layout/CourseDetails";
import webdev from "../assets/webdev.png";
import WebDevDetails from "../components/shortdetails/WebDevDetails";
import WebDevToLearn from "../components/shortdetails/WebDevToLearn";
import WebDevOutline from "../components/shortdetails/WebDevOutline";
import WebDevPayment from "../components/payments/WebDevPayment";

function WebDevelopment() {
  return (
    <>
      <CourseDetails
        heading="Web Development Basics"
        img={webdev}
        enroll={<WebDevPayment />}
        destination="Online Class"
        courseDetails={<WebDevDetails />}
        time={112}
        date="13th June 2022"
        toLearn={<WebDevToLearn />}
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        courseOutline={<WebDevOutline />}
      />
    </>
  );
}

export default WebDevelopment;
