function OfficeDetails() {
  return (
    <>
      <p>
        Learning about applications under the world of Microsoft. Microsoft
        Office is a suite of applications designed to help with productivity and
        completing common tasks on a computer.
      </p>
      <p>
        You can create and edit documents containing text and images, work with
        data in spreadsheets and databases, and create presentations and
        posters.
      </p>
    </>
  );
}

export default OfficeDetails;
