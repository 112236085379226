import CourseDetails from "../components/layout/CourseDetails";
import coreldraw from "../assets/coreldraw.png";
import CorelDrawDetails from "../components/shortdetails/CorelDrawDetails";
import CorelDrawOutline from "../components/shortdetails/CorelDrawOutline";
import CorelDrawToLearn from "../components/shortdetails/CorelDrawToLearn";
import CorelDrawPayment from "../components/payments/CorelDrawPayment";

function CorelDraw() {
  return (
    <>
      <CourseDetails
        heading="Corel Draw"
        img={coreldraw}
        price={40000}
        destination="Online Class"
        time={36}
        date="12th September 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<CorelDrawPayment />}
        courseDetails={<CorelDrawDetails />}
        toLearn={<CorelDrawToLearn />}
        courseOutline={<CorelDrawOutline />}
      />
    </>
  );
}

export default CorelDraw;
