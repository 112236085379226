function CopywritingToLearn() {
  return (
    <>
      <ul>
        <li>Strong writing</li>
        <li>Customer insight.</li>
        <li>An understanding of marketing and sales.</li>
        <li>Knowing how to turn features into benefits.</li>
        <li>Versatility.</li>
        <li>Creativity for generating convincing headlines.</li>
      </ul>
    </>
  );
}

export default CopywritingToLearn;
