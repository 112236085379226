function CorelDrawToLearn() {
  return (
    <>
      <ul>
        <li>Introduction to Fonts</li>
        <li>Overview of the Text Tool</li>
        <li>Adding and Editing Text</li>
        <li>Working with Variable Fonts</li>
        <li>Manipulating Text with the Shape Tool.</li>
        <li>Formatting Paragraph Text in CorelDRAW</li>
        <li>Adding Effects to Text.</li>
        <li>Create a Mosaic Text Effect.</li>
        <li>
          Learn how to create logos, business cards, brochures, flyers, posters,
          website layouts, wallpapers and more with Corel Draw.
        </li>
      </ul>
    </>
  );
}

export default CorelDrawToLearn;
