import styles from "../../css/learn.module.css";

function CourseTile(props) {
  return (
    <>
      <div className={styles.courseTile}>
        <div className={styles.courseImage}>
          <img src={props.courseImage} alt="web development" width="100%" />
        </div>
        <div className={styles.courseHeading}>
          <h3 className={styles.courseHeadingTitle}>{props.title}</h3>
        </div>
        <div className={styles.courseDescription}>
          <p>{props.description}</p>
        </div>
        <div className={styles.courseDate}>Session Starts: {props.date}</div>
        <div className={styles.courseFooter}>
          <div className={styles.learnMore}>
            <div className={styles.courseDestination}>{props.destination}</div>
            <div className={styles.coursePrice}>
              ₦{props.price.toLocaleString()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseTile;
