import styles from "../../css/coursedetails.module.css";

function JavascriptOutline() {
  return (
    <>
      <p>
        <strong>This Course is also available in this program:</strong>
      </p>
      <ul className={styles.learnStruct}>
        <li>Web Development</li>
        <li>Frontend Development</li>
      </ul>
    </>
  );
}

export default JavascriptOutline;
