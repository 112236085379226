function BootstrapDetails() {
  return (
    <>
      <p>
        Bootstrap is a powerful front-end framework for faster and easier web
        development.
      </p>
      <p>
        It includes HTML and CSS based design templates for creating common user
        interface components like forms, buttons, navigations, dropdowns,
        alerts, modals, tabs, accordions, carousels, tooltips, and so on.
      </p>
      <p>
        Using Bootstrap you can easily create responsive websites that appear
        more appropriately on different devices and screen resolutions without
        any change in markup.
      </p>
    </>
  );
}

export default BootstrapDetails;
