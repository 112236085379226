function CssDetails() {
  return (
    <>
      <p>This course covers all the fundamentals of CSS</p>
      <p>
        CSS is the language we use to style an HTML document. CSS describes how
        HTML elements should be displayed.
      </p>
      <p>
        CSS gives lots of flexibility to set the style properties of an element.
        You can write CSS once; and then the same code can be applied to the
        groups of HTML elements, and can also be reused in multiple HTML pages.
      </p>
    </>
  );
}

export default CssDetails;
