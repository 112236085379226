function GraphicsDetails() {
  return (
    <>
      <p>
        Graphic design is a craft where professionals create visual content to
        communicate messages. Learn the Introduction to Graphics Design to
        understand how design works.
      </p>
      <p>
        In essence, graphic design is the art or skill of combining text and
        pictures in advertisements, magazines, or books.
      </p>
      <p>
        Increasingly, graphic design and web design go hand-in-hand. Magazines
        must have an online presence, and so do newspapers, grocery stores,
        hospitals, and other kinds of businesses and institutions. Therefore,
        graphic designers must create and maintain a consistent look and feel
        across many disciplines. Most often, the digital design guides how the
        rest of the identity is created and executed.
      </p>
    </>
  );
}

export default GraphicsDetails;
