// import { Outlet } from "react-router";
// import { NavLink } from "react-router-dom";
import "./Pricing.css";

function Pricing() {
  return (
    <div>
      <section className="py-md-2 py-3 bg-dark mb-5">
        <div className="container mt-5 py-lg-4">
          <div className="col-lg-8 col-md-10 mx-auto">
            <h1 className="display-4 text-center text-light">Pricing</h1>
            <p className="mb-sm-5 pb-3 lead text-center text-light opacity-60">
              At CodeCamp we believe that the cost of Information Technology
              Services should be viewed as an investment in the scope of
              business available to any organization. Our fees are flexible,
              healthy and mutually beneficial negotiations are always
              encouraged. Contact us if you already have a budget.
            </p>
          </div>
        </div>
      </section>
      <div className="container">
        <table className="table">
          <thead>
            <tr className="table-info">
              <th scope="col">Service</th>
              <th scope="col">Bronze Plan</th>
              <th scope="col">Silver Plan</th>
              <th scope="col">Gold Plan</th>
              <th scope="col">Diamond Plan</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" className="table-info">
                Social Media Marketing
              </th>
              <td>N 40,000</td>
              <td>N 75,000</td>
              <td>N 110,000</td>
              <td>N 150,000</td>
            </tr>
            <tr>
              <th scope="row" className="table-info">
                Search Engine Marketing
              </th>
              <td>N 30,000</td>
              <td>N 50,000</td>
              <td>N 100,000</td>
              <td>N 150,000</td>
            </tr>
            <tr>
              <th scope="row" className="table-info">
                Display Adverts
              </th>
              <td>N 40,000</td>
              <td>N 70,000</td>
              <td>N 100,000</td>
              <td>N 200,000</td>
            </tr>
            <tr>
              <th scope="row" className="table-info">
                Email Marketing
              </th>
              <td>N 50,000</td>
              <td>N 80,000</td>
              <td>N 200,000</td>
              <td>N 300,000</td>
            </tr>
            <tr>
              <th scope="row" className="table-info">
                Global Marketing
              </th>
              <td>N 50,000</td>
              <td>N 80,000</td>
              <td>N 120,000</td>
              <td>N 150,000</td>
            </tr>
            <tr>
              <th scope="row" className="table-info">
                Video Marketing
              </th>
              <td>N 50,000</td>
              <td>N 80,000</td>
              <td>N 100,000</td>
              <td>N 150,000</td>
            </tr>
            <tr>
              <th scope="row" className="table-info">
                Local Listing
              </th>
              <td>N 20,000</td>
              <td>N 20,000</td>
              <td>N 20,000</td>
              <td>N 20,000</td>
            </tr>
            <tr>
              <th scope="row" className="table-info">
                Landing Page/Website
              </th>
              <td>N 150,000</td>
              <td>N 150,000</td>
              <td>N 150,000</td>
              <td>N 150,000</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* <div className="container mb-5 navi-holder">
        <ul className="nav nav-pills nav-fill flex-column flex-sm-row mb-3">
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="graphics"
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "#fff",
                      textDecoration: "none",
                    }
                  : { color: "#0d6efd", textDecoration: "none" }
              }
            >
              Graphic Design
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="web-dev"
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "#fff",
                      textDecoration: "none",
                    }
                  : { color: "#0d6efd", textDecoration: "none" }
              }
            >
              Web Development
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="social-media">
              Social Media Management
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="video-animation">
              Video Animation
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="it-services">
              Digital Marketing
            </NavLink>
          </li>
        </ul>
      </div>

      <Outlet /> */}
    </div>
  );
}

export default Pricing;
