import CourseDetails from "../components/layout/CourseDetails";
import office from "../assets/office.png";
import OfficeDetails from "../components/shortdetails/OfficeDetails";
import OfficeToLearn from "../components/shortdetails/OfficeToLearn";
import OfficeOutline from "../components/shortdetails/OfficeOutline";
import OfficePayment from "../components/payments/OfficePayment";

function Office() {
  return (
    <>
      <CourseDetails
        heading="Microsoft Office"
        img={office}
        price={70000}
        destination="Online Class"
        time={36}
        date="12th September 2022"
        courseModule="This course would be taken online using the Zoom meetings platform, with a flexible schedule, classes would be held 2 hours a day for 3 times on weekdays and a 2hrs practical class on saturdays."
        enroll={<OfficePayment />}
        courseDetails={<OfficeDetails />}
        toLearn={<OfficeToLearn />}
        courseOutline={<OfficeOutline />}
      />
    </>
  );
}

export default Office;
