import styles from "../../css/coursedetails.module.css";

function FrontendOutline() {
  return (
    <>
      <p>
        <strong>This Course is also available in this program:</strong>
      </p>
      <ul className={styles.learnStruct}>
        <li>HTML5</li>
        <li>CSS3</li>
        <li>Javascript</li>
        <li>Bootstrap</li>
        <li>React JS</li>
      </ul>
    </>
  );
}

export default FrontendOutline;
