function CopywritingDetails() {
  return (
    <>
      <p>
        Learn about Copywriting.Copywriting is the act or occupation of writing
        text for the purpose of advertising or other forms of marketing.
      </p>
      <p>
        The product, called copy or sales copy, is written content that aims to
        increase brand awareness and ultimately persuade a person or group to
        take a particular action.
      </p>
    </>
  );
}

export default CopywritingDetails;
