function OfficeToLearn() {
  return (
    <>
      <ul>
        <li>Microsoft Word</li>
        <li>Microsoft Excel</li>
        <li>Microsoft PowerPoint</li>
      </ul>
    </>
  );
}

export default OfficeToLearn;
