function HtmlToLearn() {
  return (
    <>
      <ul>
        <li>
          How to publish documents online with text, images, lists, tables, etc
        </li>

        <li>
          How to access web resources such as images, videos or other HTML
          document via hyperlinks
        </li>
        <li>
          How to create forms to collect user inputs like name, e-mail address,
          comments, etc
        </li>
        <li>
          How to include images, videos, sound clips, flash movies, applications
          and other HTML documents directly inside an HTML document.
        </li>
        <li>
          How to create offline version of your website that work without
          internet.
        </li>
      </ul>
    </>
  );
}

export default HtmlToLearn;
